<template>
  <div class="login-page">
    <div class="login-page__content-wrapper">
      <div class="login-page__content">
        <div class="login-page__top">
          <a href="/" class="logo"></a>
        </div>
        <div class="login-page__form">
          <PhonePage v-if="!isSend" @send="send" />
          <AuthCodePage v-else :data="data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./login-page.scss";

import PhonePage from "@index/views/auth/phone/Phone.vue";
import AuthCodePage from "@index/views/auth/code/Code.vue";

import store from "@index/store";

export default {
  name: "Auth",
  data() {
    return {
      isSend: false,
      data: {},
    };
  },
  methods: {
    send(data) {
      this.data = data;
      this.isSend = true;
    },
  },
  components: {
    PhonePage,
    AuthCodePage,
  },
  beforeRouteEnter(to, from, next) {
    const {
      contactData: { phone },
    } = store.getters["application/user"];

    if (!phone) {
      next();
    } else {
      window.location.href = "/lk";
    }
  },
};
</script>
