<template>
  <form @submit.prevent="submit" novalidate class="auth-code-page">
    <h1 class="auth-code-page__title">Введите код из СМС</h1>
    <div class="auth-code-page__form">
      <Field
        title="Код"
        mask="#####"
        v-model="form.code"
        :error="formErrors.code"
      />
      <div
        class="auth-code-page__timer"
        :class="{
          'auth-code-page__timer-inactive': timer,
        }"
      >
        <span v-if="!timer" @click="resendCode"> Отправить код повторно </span>
        <span v-else> {{ timer }} - Отправить код повторно </span>
      </div>
    </div>
    <div class="auth-code-page__actions">
      <Button type="submit"> Войти </Button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";

import "./auth-code-page.scss";
import Field from "@index/components/common/field/Field";
import Button from "@index/components/gui/button/Button";

import auth from "@index/api/auth";

import validation from "@index/mixins/validation";

import Validation from "@index/ext/validation/Validation";

export default {
  name: "AuthCodePage",
  mixins: [validation],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        code: "",
      },
      formRules: {
        code: [Validation.REQUIRED, [Validation.MIN, 5]],
      },
      timer: 60,
    };
  },
  created() {
    this.setTimer(60);
  },
  computed: {
    ...mapGetters({
      code_hash: ["application/code"],
    }),
  },
  watch: {
    "form.code"(v) {
      v.length === 5 && this.submit();
    },
  },
  methods: {
    async resendCode() {
      this.timer = 60;
      await auth(this.data);
      this.setTimer(60);
    },
    async submit() {
      this.validate();

      if (!this.isValid) return;

      const { wrongCode, result } = await auth({
        ...this.data,
        ...this.form,
        code_hash: this.code_hash,
      });

      this.formErrors.code = wrongCode ? "Неверный код" : "";

      if (result) {
        this.$store.commit("application/load", true);
        window.location.href = "/";
      }
    },
    setTimer(time) {
      if (time < 0) time = 0;

      this.timer = time;

      if (time === 0) return;

      setTimeout(() => {
        this.setTimer(time - 1);
      }, 1000);
    },
  },
  components: {
    Field,
    Button,
  },
};
</script>
