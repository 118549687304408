<template>
  <form @submit.prevent="submit" novalidate class="phone-page">
    <h1 class="phone-page__title">Личный кабинет</h1>
    <Phone v-model="form.phone" :error="formErrors.phone" title="Телефон" />
    <div class="phone-page__actions">
      <Button type="submit" class="phone-page__next-btn"> Получить код </Button>
      <Button @click.native="back" secondary> Вернуться </Button>
    </div>
  </form>
</template>

<script>
import "./phone-page.scss";

import validation from "@index/mixins/validation";
import Validation from "@index/ext/validation/Validation";

import Phone from "@index/components/common/field/Phone";
import Button from "@index/components/gui/button/Button";

import auth from "@index/api/auth";

export default {
  name: "PhonePage",
  mixins: [validation],
  data() {
    return {
      form: {
        phone: "",
      },
      formRules: {
        phone: [Validation.REQUIRED, Validation.PHONE],
      },
    };
  },
  methods: {
    async submit() {
      this.validate();

      if (!this.isValid) return;

      await auth(this.form);

      this.$emit("send", this.form);
    },
    back() {
      // this.$router.push({
      //     name: 'Main'
      // })

      window.location.href = "/";
    },
  },
  components: {
    Phone,
    Button,
  },
};
</script>
